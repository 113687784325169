<template>
  <div>
    <el-form :model="form"
             label-width="60px">
      <el-row :gutter="10">
        <el-col :span="6">
          <el-form-item label="课室">
            <el-select v-model="form.class_room_id"
                       style="width:100%"
                       clearable
                       filterable
                       placeholder="请选择">
              <el-option v-for="item in classRoom"
                         :key="item.id"
                         :label="item.room_name"
                         :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-button type="primary"
                     @click="search">查询</el-button>
          <el-button type="primary"
                     @click="addClass">新增</el-button>
        </el-col>
      </el-row>
    </el-form>
    <el-row :gutter="10"
            style="padding-bottom:10px">

    </el-row>
    <Table ref="TablesRef"
           :tableData="tableData" />
    <Form ref="FormRef" />
    <pagination ref="pagination"
                :get-data="initData"
                :now-page.sync="page"
                :now-size.sync="size"
                :total="totalElements" />
  </div>
</template>

<script>
import Form from './components/form.vue'
export default {
  data () {
    return {
      form: {
        class_room_id: '',
      },
      statusList: [
        { label: '开启', value: 1 },
        { label: '关闭', value: 0 },
      ],
      page: 1,
      size: 10,
      totalElements: 0,
      tableData: [],
      pageName: 'classes_course_admin',
      tableColumns: [
        { prop: 'id', align: 'center', label: '编号', width: '80', showToopic: false },
        { prop: 'title', align: 'center', label: '课程名称', width: '', showToopic: false },
        { prop: 'room_name', align: 'center', label: '课室', width: '', showToopic: false },
        { prop: 'edu_user_name', align: 'center', label: '带班老师', width: '', showToopic: false },
        { prop: 'subject_name', align: 'center', label: '科目', width: '', showToopic: false },

        { prop: 'grade_name', align: 'center', label: '年级', width: '', showToopic: false },
        { prop: 'class_name', align: 'center', label: '班级名称', width: '', showToopic: false },
        { prop: 'start_time', align: 'center', label: '上课时间', width: '', showToopic: false },
        { prop: 'end_time', align: 'center', label: '下课时间', width: '', showToopic: false },
        {
          prop: 'd', align: 'center', label: '操作', width: '', type: 'img', btns: [
            { content: '删除', type: 'del', event: 'delRow' },
          ]
        },
      ],
      schoolList: [],
      classRoom: [],
    }
  },
  components: {
    Form
  },
  mounted () {
    this.setTablesColums()
    this.getList()
  },
  methods: {
    getList () {
      this.$http({
        url: '/api/v1/school/get_school',
        method: 'get'
      }).then(res => {
        this.schoolList = res.data.list
      })
      this.$http({
        url: '/api/v1/special/room_lst',
        method: 'get'
      }).then(res => {
        this.classRoom = res.data.list
      })
    },
    getClass (prop, value) {
      if (prop == 'status') {
        return value ? 'pass' : 'notpass'
      }
    },
    getValue (prop, value) {
      if (prop == 'status') {
        return value ? '开启' : '关闭'
      }
    },
    setTablesColums () {
      this.$refs.TablesRef.pageName = this.pageName
      this.$refs.TablesRef.setColumns(this.tableColumns)
    },
    initData (page, limit) {
      let form = JSON.parse(JSON.stringify(this.form))
      form.page = page
      form.limit = limit
      this.$http({
        url: '/api/v1/special/schedule_lst',
        method: 'get',
        params: form
      }).then(res => {
        this.tableData = res.data.list
        this.totalElements = res.data.count
      })
    },
    delRow (row) {
      this.$confirm("此操作将删除该条数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$http({
          url: '/api/v1/special/schedule_del',
          method: 'post',
          data: {
            id: row.id
          }
        })
      }).then(() => {
        this.$notify({
          title: '提示',
          message: '成功!',
          type: 'success'
        });
        setTimeout(() => {
          this.refresh()
        }, 500);
      })
    },
    addClass () {
      let form = {
        title: '',
        subtitle: '',
        class_room_id: '',
        start_time: '',
        class_id: '',
        end_time: '',
        notice: '',
        special_class_subject_id: '',
        edu_user_id: ''
      }
      this.$refs.FormRef.form = form
      this.$refs.FormRef.dialogVisible = true
    },
    // 没有修改功能
    showForm (row) {
      let form = JSON.parse(JSON.stringify(row))
      this.$refs.FormRef.form = form
      this.$refs.FormRef.dialogVisible = true
    },
    // 刷新
    refresh () {
      this.$refs.pagination.shuaxin()
    },
    // 查询
    search () {
      this.$refs.pagination.toFirstPage()
    },
  }
}
</script>

<style lang="scss" scoped>
</style>
